import tracking from '@finn-no/analytics';
import * as braze from '@braze/web-sdk';

import pulseTrack, { setHasInternalApplication } from './lib/pulse-track';
import isExtendedProfile from './lib/isExtendedProfile';

export default (win) => {
    const { document, setTimeout } = win;

    const isolatedPodlet = document.querySelector('jobapplypodlet-isolated');
    const shadowRoot =
        isolatedPodlet.shadowRoot ||
        isolatedPodlet.querySelector('template').content;
    const applyButton = shadowRoot.querySelector('#job-apply-button');

    if (!applyButton) {
        // Likely due to fallback m arkup
        return;
    }

    const { jobApplicationType, brazeApiKey, brazeEndpoint, userId } =
        applyButton.dataset;

    if (userId) {
        braze.initialize(brazeApiKey, {
            baseUrl: brazeEndpoint,
        });

        braze.changeUser(userId);
    }

    const target = applyButton.getAttribute('target');
    const url = applyButton.getAttribute('href');
    // Possible types: internal,external
    const isInternal = jobApplicationType === 'internal';

    const qs = new URLSearchParams(win.location.search);
    const finnkode = qs.get('finnkode');

    function handleClick(e) {
        let track = tracking.trackEvent;

        const hasExtendedProfile = isExtendedProfile(win);
        pulseTrack(finnkode, isInternal, hasExtendedProfile);

        if (userId) {
            braze.logCustomEvent('job_apply_now');
        }

        if (target === '_self') {
            if (!isInternal) {
                e.preventDefault();
                // We give the browser 500ms to ship the tracking event before sending the user to the external url.
                // The reason only mobile is targeted is because the url gets opened in new tab on desktop.
                setTimeout(() => {
                    win.document.location.assign(url);
                }, 500);
            } else {
                track = tracking.trackEventLater;
            }
        }

        const eventTrackingConfig = {
            context: {
                list2: `cta:${jobApplicationType}-application`,
            },
            analyticsName: 'contact.apply-for-position.click',
            eventFlags: isInternal ? 'start' : 'ltp',
            kpi: 'effect',
            ecCategory: 'contact.apply-for-position',
            ecType: 'USER',
            pulse: {
                type: 'Apply',
                object: {
                    type: 'UIElement',
                    id: 'apply-for-a-job',
                    name: 'Apply for a job',
                },
                target: {
                    type: 'ClassifiedAd',
                },
            },
        };

        // Set 'target' for Pulse tracking.
        tracking.getApi((api) => {
            const { context } = api.prepareContext();
            const pulseTarget = eventTrackingConfig.pulse.target;
            if (pulseTarget) {
                pulseTarget.id = context.finnkode;
                pulseTarget.contentId = context.finnkode;
                pulseTarget.category = context.category;
                pulseTarget.name = document.title;
            }
        });

        track(eventTrackingConfig);

        track({
            ecCategory: 'LOG_JOB_ONLINE_APPLICATION',
            ecType: 'AD',
        });

        // Remove event handler to prevent it being called twice
        applyButton.removeEventListener('click', handleClick);
    }

    applyButton.addEventListener('click', handleClick);

    setHasInternalApplication(isInternal, finnkode);
};
