'use strict';

import pulse from '@finn-no/pulse-sdk';

export default function pulseTrack(finnCode, isInternal, hasExtendedProfile) {
    const target = {
        id: finnCode,
        type: isInternal ? 'Form' : 'ExternalContent',
        name: isInternal ? 'Job apply page' : 'Go to webpage',
        referralCommission: isInternal ? undefined : false,
    };

    pulse.trackEvent(
        {
            type: 'Click',
            intent: 'Apply',
            name: 'Apply for position',
            object: {
                id: 'apply-for-position-btn',
                type: 'UIElement',
                elementType: 'Button',
                items: [
                    {
                        type: 'ClassifiedAd',
                        id: finnCode,
                        contentId: finnCode,
                        name: 'n/a',
                        category: 'n/a',
                        items: [
                            {
                                type: 'Job',
                                hasInternalApplication: isInternal,
                            },
                        ],
                        hasExtendedProfile,
                    },
                ],
            },
            target,
            vertical: {
                name: 'job',
            },
        },
        // The Finn Pulse SDK will add the ClassifiedAd object to '.object.items'
        // automatically when the event is in the context of a ClassifiedAd page view.
        // We (currently) don't want this in this case since the underlying page view
        // don't include the Job item.
        { disableAddClassifiedAdToItems: true },
    );
}

export function setHasInternalApplication(hasInternalApplication, adId) {
    pulse.setPageState({
        object: {
            items: [
                {
                    type: 'Job',
                    id: adId,
                    hasInternalApplication,
                },
            ],
        },
    });
}
